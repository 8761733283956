<template>
<!-- 客户子账号详情页 -->
    <div id="app_user_son_detail">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                
            </div>
            <div class="head_top_title">子账号详情</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 0px;background-color: white;">
            <el-row style="overflow: auto;" :style="{height: (content_heigth+'px')}">

                <el-row class="dialog_top cursor" @click.native="show_base = !show_base" style="text-align: left;padding-left:15px;">
                    <span>基本信息</span>
                    <i class="dialog_top_close_button" :class="show_base ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>
                <!-- 基本信息 -->
                <el-table v-if="show_base" :data="tableData_base" :show-header="false"  style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="label"  width="110"  align="center"> </el-table-column>
                    <el-table-column  prop="show_value"  min-width="200" align="right"> </el-table-column>
                    <!-- <el-table-column   label="操作"  min-width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini"  @click="detail(scope.cpid)">客户详情</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
                <!-- 基本属性修改按钮 -->
                <el-row v-if="show_base" type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                     <el-button type="primary" size="mini" style="padding:10px 50px;" @click="base_upd()">修改</el-button>
                </el-row>

                <!-- 子账号 -->
                <el-row v-if="detail_obj.sonCpidArray && detail_obj.sonCpidArray.length>0" class="dialog_top cursor" @click.native="cpid_son_show(detail_obj.cpid)" style="text-align: left;padding-left:15px;">
                    <span>子账号</span>
                    <i class="dialog_top_close_button el-icon-arrow-right" style="font-size:24px"></i>
                </el-row>

            </el-row>
        </el-row>
        
        <!-- 客户基本信息-子账号修改组件 -->
        <UserUpdBaseSon :prop_change_i="prop_user_upd_base_i"
            :prop_item="detail_obj"
            @result="userUpdResult"
        ></UserUpdBaseSon>
    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import UserUpdBaseSon from './user_upd_base_son.vue';//组件 客户基本信息子账号修改

//import axios from "axios";
export default {

    components: {
       UserUpdBaseSon
    },

    data(){
        return{
            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            detail_obj : {},//客户详情的对象  通过请求接口获取

            show_base:true,//客户基本信息 是否展示  true:展示
            tableData_base:[],//客户基本信息数据

            //客户基本信息子账号修改
            prop_user_upd_base_i:0,//弹出框是否可见
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.detail_obj = this.$options.data().detail_obj;//单个恢复初始化
        // this.show_base = this.$options.data().show_base;//单个恢复初始化
        // this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化

        //重置所有数据
        Object.assign(this.$data, this.$options.data.call(this));
        //初始化设置
        this.initSetUp();
        
        //获取数据
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },

        //子账号的展示
        cpid_son_show(cpid){
            console.log("子账号的展示"+cpid);
            var cpidOne = this.$route.params.id;
            API.router_to("/user_son_list/"+cpidOne+"/"+cpid);
        },

        //基本信息修改
        base_upd(){
            console.log("基本信息修改");
            if(this.detail_obj.cpid){
                this.prop_user_upd_base_i ++;//弹出框是否可见
            }
        },
        //修改结果
        userUpdResult(){
            this.getData();
        },


        //获取数据
        getData(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化

            var cpidOne = this.$route.params.id;
            var cpidTwo = this.$route.params.cpidTwo;
            var cpidThree = this.$route.params.cpidThree;
            var cpid = cpidTwo;
            if(cpidThree != "000000"){
                cpid = cpidThree;
            }
            //请求接口
            API.UserServlet({
                param: "son_detail",
                cpid:cpid,
            }).then((res) => {
                this.loading = false;
                //console.log(res);
                if (res.recode === 0) {

                    this.detail_obj = res.detail;
                    var show_value = "";

                    var table_obj = {label:"客户账号",show_value:res.detail.cpid,value:res.detail.cpid,name:"cpid"};
                    this.tableData_base.push(table_obj);

                    if( 1 == 1){
                        table_obj = {label:"公司名",show_value:res.detail.company_name,value:res.detail.company_name,name:"company_name"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"公司商务类型",show_value:res.detail.business_type == 0 ? '渠道' : '直客',value:res.detail.business_type,name:"business_type"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"公司鉴权IP",show_value:res.detail.ip == '' ? res.detail.company_ip : '无效',value:res.detail.company_ip,name:"company_ip"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"累计充值",show_value:res.detail.nummax,value:res.detail.nummax,name:"nummax"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"剩余条数",show_value:res.detail.blanceNum,value:res.detail.blanceNum,name:"blanceNum"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"登录密码",show_value:res.detail.login_pwd,value:res.detail.login_pwd,name:"login_pwd"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"接口密码",show_value:res.detail.cppwd,value:res.detail.cppwd,name:"cppwd"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"私钥",show_value:res.detail.secret,value:res.detail.secret,name:"secret"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"联系方式",show_value:res.detail.relationphone,value:res.detail.relationphone,name:"relationphone"};
                        this.tableData_base.push(table_obj);

                        if(res.detail.states == 0){
                            show_value = "可用";
                        }else if(res.detail.states == 1){
                            show_value = "注销";
                        }else if(res.detail.states == 2){
                            show_value = "已删除";
                        }else if(res.detail.states == 3){
                            show_value = "暂停";
                        }else{
                            show_value = "未知";
                        }
                        table_obj = {label:"客户状态",show_value:show_value,value:res.detail.states,name:"states"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户付费类型",show_value:res.detail.user_type == 0 ? '预付费' : '后付费',value:res.detail.user_type,name:"user_type"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"失败是否返还",show_value:res.detail.fail_rtid == '' ? '不返还' : '返还',value:res.detail.fail_rtid,name:"fail_rtid"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户鉴权IP",show_value:res.detail.ip,value:res.detail.ip,name:"ip"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户接入方式",show_value:res.detail.interface_type == 0 ? 'CMPP' : 'HTTP',value:res.detail.interface_type,name:"interface_type"};
                        this.tableData_base.push(table_obj);

                        if(res.detail.interface_type != 0){//接入方式，0:CMPP；1:HTTP
                            table_obj = {label:"回调地址",show_value:res.detail.url,value:res.detail.url,name:"url"};
                            this.tableData_base.push(table_obj);

                            table_obj = {label:"上行地址",show_value:res.detail.mourl,value:res.detail.mourl,name:"mourl"};
                            this.tableData_base.push(table_obj);
                        }
                        
                        table_obj = {label:"余额提醒",show_value:res.detail.blanceCallNum == -1 ? '不提醒' : '提醒',value:res.detail.blanceCallNum,name:"blanceCallNum"};
                        this.tableData_base.push(table_obj);
                        if(res.detail.blanceCallNum != -1){
                            table_obj = {label:"提醒金额(条)",show_value:res.detail.blanceCallNum+'条',value:res.detail.blanceCallNum,name:"blanceCallNum"};
                            this.tableData_base.push(table_obj);
                            table_obj = {label:"提醒目标号码",show_value:res.detail.phones,value:res.detail.phones,name:"phones"};
                            this.tableData_base.push(table_obj);
                        }
                        

                        table_obj = {label:"关联微信",show_value:res.detail.wxArray.length,value:res.detail.wxArray.length,name:"wxArray"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"已发送条数",show_value:res.detail.havedNum,value:res.detail.havedNum,name:"havedNum"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"当日已发条数",show_value:res.detail.currentNum,value:res.detail.currentNum,name:"currentNum"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"备注",show_value:res.detail.remark,value:res.detail.remark,name:"remark"};
                        this.tableData_base.push(table_obj);
                    }

           
                }
                
            });
        },
        //返回
        goBack() {
            var cpidOne = this.$route.params.id;
            var cpidTwo = this.$route.params.cpidTwo;
            var cpidThree = this.$route.params.cpidThree;
            if(cpidThree == "000000"){
                cpidTwo = "000000";
            }
            API.router_to("/user_son_list/"+cpidOne+"/"+cpidTwo);
        }
    }
};

</script>

<style scoped>

</style>